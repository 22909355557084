'use client'

import { FC, useEffect } from 'react'
import { PageTypeProps, PropsData } from '@/types/site.interface'
import { getDataLayerUiProps } from '../elements/globalDataLayer'

const DataLayerPropsProvider: FC<PropsData<PageTypeProps>> = ({ data }) => {
  const page = data?.page

  useEffect(() => {
    if (window) {
      window.dataLayerUiState = getDataLayerUiProps(data)
    }
  }, [data, page.slug])

  return null
}

export default DataLayerPropsProvider

import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/atoms/data-layer-props-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/accordion/accordion.client.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/content-faq.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/dynamic-form/dynamic-form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/entry-slider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/header-image.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/image.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/job-search/job-search.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/job-survey/job-survey.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/testimonials.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/elements/video-player/video-player.client.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/molecules/pagination/pagination.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/molecules/site-logo.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/navigation/generic-header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/components/navigation/navigation-list.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/lib/context.js");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recrewted/src/svg/chevron-right-icon.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js")